/* Basic Config
-------------------- */

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: "Roboto", sans-serif;
  background: rgb(242, 246, 250);
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.4;
}

::selection {
  background: #4FACFE;
  color: #FFF;
}

::-moz-selection {
  background: #4FACFE;
  color: #FFF;
}